// Base theme styles.
@import '../../steindom_theme/less/base';

// Variables.
@import 'variables';

// Quick links.
.page-quick-links {
  background-color: @theme-quick-links-bg-color;
  padding: 10px 0;

  .pane-social-media-link-widget,
  .pane-hosted-search-form {
    display: inline-block;
    vertical-align: top;
    margin: 0;
  }

  .pane-social-media-link-widget {
    margin-right: 30px;

    .pane-content li {
      padding-top: 2px;
    }

    @media @mobile-only {
      float: left;
      width: 50%;
      margin: 0;

      .pane-content li {
        padding-left: 0;
      }

      img {
        width: 23px;
      }
    }
  }

  .pane-hosted-search-form {
    form {
      max-width: 250px;
      display: block;
    }
    input[type="text"] {
      background-color: #E8E8E8;
      border: none;
      border-radius: 0;
    }
    button {
      background-color: #9A9A9A;
      border: 1px solid #9A9A9A;
      color: white;
      margin-left: 0 !important;
    }
    @media @mobile-only {
      float: right;
      width: 50%;
    }
  }

  .pane-menu-quick-links {
    margin: 7px 0 0;
    text-align: right;

    .pane-content {
      font-size: 14px;
      ul {
        margin: 0;
      }
      li {
        padding: 0 0 0 15px;
      }
      a {
        color: @theme-quick-links-link-color;
      }
    }

    @media @mobile-only {
      text-align: center;
      margin: 0 0 10px;

      .pane-content {
        font-size: 10px;
        li {
          padding: 0 5px;
        }
      }
    }
  }
}

// Header.
.page-header {
  background-color: @theme-header-bg-color;
  color: @theme-header-text-color;

  // Flexbox columns desktop and larger.
  @media @desktop {
    .page-header-cols {
      display: flex;
    }
    .page-brand, .page-nav {
      width: 50%;
    }

    // Assists with positioning of submenus.
    .pane-superfish-1,
    .pane-superfish-1 .pane-content,
    .sf-horizontal,
    li.sf-depth-1 {
      height: 100%;
    }
  }

  // Brand wrapper.
  .page-brand {
    padding: 20px 0;
  }

  // Site name.
  .pane-page-site-name {
    text-align: center;
    font-size: 26px;
    margin: 0;
    @media @mobile-only {
      font-size: 22px;
    }
    .pane-content a {
      .opacity-hover(.5, 1);
      &:hover {
        text-decoration: none;
      }
    }
  }

  // Site slogan.
  .pane-page-slogan {
    text-align: center;
    font-size: 20px;
    font-weight: 200;
    margin: 0;
    @media @mobile-only {
      font-size: 14px;
    }
  }

  // Nav.
  .pane-superfish-1 {
    margin: 0;
    @media @tablet-only {
      margin-bottom: 15px;
    }

    @media @mobile-only {
      margin: 0 -15px;
      border-top: 3px solid @theme-content-bg-color;
    }

    .pane-content {
      .superfish(@submenu-top: 100%, @padding: 15px);
      .superfish-icons(@right: '\e250', @down: '\e252', @position: 'bottom', @bottom: 0, @bottom-font-size: .75em);
      .superfish-accordion-bg-colors(@color: darken(@theme-header-bg-color, 10%));

      .sf-horizontal {
        display: flex;
        justify-content: center;
        @media @desktop {
          border-top: 3px solid @theme-header-bg-color;
          justify-content: flex-end;
        }
        > li.sf-depth-1 {
          float: none;
          transition: all 0.15s ease-in-out;
          &:hover {
            background: fade(black, 10%);
          }
          @media @desktop {
            display: flex;
            align-items: flex-start;
            border-top: 10px solid transparent;
            padding-top: 20px;
            &.sfHover {
              border-top-color: @theme-main-menu-link-color;
            }
          }
        }
        a.sf-depth-1 {
          font-family: @font-family-serif;
          color: @theme-main-menu-link-color;
          font-size: 18px;
        }
        li.sf-depth-1.active-trail a.sf-depth-1 {
          opacity: .8;
        }
        ul {
          background-color: white;
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
          li.active-trail > a {
            color: #646464;
          }
          a {
            padding: 10px;
            border-bottom: 1px solid #eaeaea;
            &.active-trail {
              background: darken(white, 5%);
            }
            &:hover {
              background: darken(white, 10%);
            }
          }
        }
      }

      .sf-accordion-toggle a,
      .sf-accordion a {
        color: @theme-main-menu-link-color;
      }
    }
  }
}

// Main page content.
.page-main {
  background-color: @theme-content-bg-color;
  padding-top: 3px;
}

// Page image.
.page-image {
  margin-bottom: 3px;
  background-color: darken(@theme-title-bg-color, 30%);
  background-size: cover;
  background-position: center;
  min-height: 300px;

  @media @mobile-only {
    min-height: 200px;
  }

  .panel-pane {
    margin: 0;
  }
}

// Page title.
.page-title {
  background-color: @theme-title-bg-color;
  color: @theme-title-text-color;
  text-align: center;
  font-family: @font-family-serif;

  .panel-pane {
    margin: 0;
  }

  h1 {
    margin: 25px;
    font-size: 40px;
    @media @mobile-only {
      font-size: 30px;
    }
  }
}

// Page content.
.page-content {
  background-color: @theme-content-bg-color;
  padding: 45px 0;
}

// Footer.
.page-footer {
  margin-top: 60px;
  font-weight: lighter;

  .pane-title, .pane-content {
    color: @theme-footer-text-color;

    a:not(.btn) {
      text-decoration: underline;
      color: @theme-footer-link-color;
      &:hover {
        color: @theme-footer-link-color;
      }
    }
  }
}

// Credit.
.page-credit {
  margin: 40px 0 20px;
  .panel-pane {
    margin-bottom: 0;
  }
  .pane-content {
    text-align: right;
    line-height: 18px;
    font-size: 14px;
    color: white;
    .opacity-hover(1, .5);
    a {
      text-decoration: underline;
      color: inherit;
    }
  }
}

// Default pane titles.
.pane-title {
  font-size: 26px;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 15px;
  position: relative;

  @media @mobile-only {
    font-size: 20px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 1px;
    bottom: 0;
    left: 50%;
    margin-left: -25px;
    border-bottom: 2px solid @theme-title-bg-color;
  }
}

// Pane styles.
@import 'pane-styles';

// Submenu.
.pane-submenu {
  .pane-style(@theme-submenu-bg-color);

  > .pane-title {
    text-align: left;
    border-bottom: 2px solid white;
    &:before {
      display: none;
    }
  }

  > .pane-content {
    .submenu-arrows(@collapsed: '\e250', @expanded: '\e252');
    .submenu-font-size-tier();

    a {
      font-size: 18px;
      padding: 0.4em 0.4em;
      .opacity-hover(.6);
      text-decoration: none !important;
      &.active {
        opacity: .4;
        backface-visibility: hidden;
      }
    }

    li.submenu-parent:before {
      margin-top: 0.6em;
    }
  }
}

// Blockquotes.
blockquote {
  opacity: .8;
}

// Light outline buttons.
.btn-outline-light {
  .button-variant(white; transparent; white);
  &:hover {
    color: dimgrey;
    background-color: white;
    border-color: white;
  }
}

// Call to Action.
.call-to-action__title {
  font-size: 30px;
}
.call-to-action__text {
  font-weight: lighter;
}
.call-to-action__link .btn-primary {
  .button-variant(white; @theme-title-bg-color; @theme-title-bg-color);
}

// Hover button effect.
a.btn {
  transition: transform 500ms;
  &:hover {
    transform: translateY(4px);
  }
}

@brand-primary:#002c5a;@brand-success:#49aba2;@brand-info:#2486B7;@brand-warning:#E5B60B;@brand-danger:#b12c25;@main-menu-bg-color:@brand-info;@font-family-sans-serif:Quicksand, sans-serif;@font-family-serif:'Tenor Sans', serif;@font-family-script:@font-family-sans-serif;@font-family-other:@font-family-sans-serif;@theme-quick-links-bg-color:#FFFAF5;@theme-header-bg-color:@brand-primary;@theme-title-bg-color:#b12c25;@theme-pane-style-subdued-bg-color:rgba(35, 100, 170, 0.05);@theme-pane-style-info-bg-color:#49aba2;
@brand-primary: #002c5a;@brand-success: #49aba2;@brand-info: #2486B7;@brand-warning: #E5B60B;@brand-danger: #b12c25;@main-menu-bg-color: @brand-info;@font-family-sans-serif: Quicksand, sans-serif;@font-family-serif: 'Tenor Sans', serif;@font-family-script: @font-family-sans-serif;@font-family-other: @font-family-sans-serif;@theme-quick-links-bg-color: #FFFAF5;@theme-header-bg-color: @brand-primary;@theme-title-bg-color: #b12c25;@theme-pane-style-subdued-bg-color: rgba(35, 100, 170, 0.05);@theme-pane-style-info-bg-color: #49aba2;